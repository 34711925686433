import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Space, Spin, Table, Typography, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import ItemImage from '../../assets/images/item.png';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import EditableCell from '../common/EditableCell';

const UOMList = () => {
	const [uomList, setUOMList] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	const symbolInputRef = useRef(null);
    const descriptionInputRef = useRef(null);
	
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const isEditing = (record) => record.symbol === editingKey;

	useEffect(() => {
		fetchUOMList();
	}, []);

	const edit = (record) => {
		form.setFieldsValue({
			description: '',
			...record,
		});
		setEditingKey(record.symbol);
	};

	const cancel = () => {
		setEditingKey('');
	};

	const update = async (key) => {
		try {
			const row = await form.validateFields();
			const newData = [...uomList];
			const index = newData.findIndex((item) => key === item.symbol);
			if (index > -1) {
				const item = newData[index];
				// Update API 
				handleUpdate(item.symbol, row.description);

				newData.splice(index, 1, {
					...item,
					...row,
				});
				setUOMList(newData);
				setEditingKey('');
			} else {
				newData.push(row);
				setUOMList(newData);
				setEditingKey('');
			}
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	const fetchUOMList = () => {
		httpCall({
			method: 'GET',
			url: '/api/uom/get',
		})
			.then((response) => {
				if (response) {
					setUOMList(response?.data);
				} else {
					console.error(
						'Error retrieving category list',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving item type list', error);
			});
	};

    const openNotification = (msg, desc) => {
		api.open({
		  message: msg,
		  duration: 2,
		  description: desc,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleSave = () => {
		httpCall({
			method: 'POST',
			url: '/api/uom/create',
			payload: {
				symbol: symbolInputRef.current.input.value,
                description: descriptionInputRef.current.input.value,
			},
		})
		.then((response) => {
			if (response.data.success) {
				openNotification('Add Success !', 'New UOM Added Successfully, Updating Grid. ');
				symbolInputRef.current.value = "";
                descriptionInputRef.current.value = "";
				fetchUOMList();
			}
		})
		.catch((error) => {
			console.error(error);
		});
	};

	const handleUpdate = (symbol, description) => {
		httpCall({
			method: 'PUT',
			url: '/api/uom/update/' + symbol,
			payload: {
				symbol,
				description
			},
		})
		.then((response) => {
			if (response.data.success) {
				openNotification('Update Success !', 'Description Updated Successfully, Updating Grid. ');
			}
		})
		.catch((error) => {
			console.error(error);
		});
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/uom/delete/'+ id,
		}).then(reponse => {
			openNotification('Delete Success !', 'UOM Deleted Successfully, Refreshing Grid. ');
			fetchUOMList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};

	const handleTotal = (total, range) => (
		<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
	);

    const columnsNew = [
		{
			title: 'Symbol',
			dataIndex: 'symbol',
			key: 'symbol',
			align: 'center',
			width: 300
		},		
        {
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			align: 'center',
			editable: true,
			width: 300
		},	
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={() => update(record.symbol)}
							style={{
								marginRight: 8,
							}}
						> Save
						</Typography.Link>
						<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
							<a>Cancel</a>
						</Popconfirm>
					</span>
				) : (
					<Space size="middle" disabled={editingKey !== ''}>
						<EditOutlined
							style={{
								cursor: 'pointer',
								fontSize: '20px',
								color: '#08c',
							}}
							onClick={() => edit(record)}
						/>
						<Popconfirm
							title="Are you sure you want to delete?"
							onConfirm={() => handleDelete(record.symbol)}
						>
							<DeleteOutlined
								style={{ fontSize: '20px', color: '#FF6868' }}
							/>
						</Popconfirm>
					</Space>
				);}		
		  },
	];

	const mergedColumns = columnsNew.map((col) => {
		if (!col.editable) {
		  return col;
		}
		return {
		  ...col,
		  onCell: (record) => ({
			record,
			inputType: 'text',
			dataIndex: col.dataIndex,
			title: col.title,
			editing: isEditing(record),
		  }),
		};
	  });

	const CategoryAdd = () => {
		return (
			<div style={{ marginTop: '2rem', marginLeft: '2rem', width: '100%', display: 'flex', gap: '20px', justifyContent: 'start', alignItems: 'center' }}>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Symbol</span>
					<Input
						type="text"
						placeholder='Enter Symbol'							
						style={{ width: '250px', marginBottom: '30px' }}
						ref={symbolInputRef}							
					/>
				</Space>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Description</span>
					<Input
						type="text"
						placeholder='Enter Description'							
						style={{ width: '250px', marginBottom: '30px' }}
						ref={descriptionInputRef}							
					/>
				</Space>
				<Button type='primary' 
						style={{backgroundColor: 'green'}} 
						onClick={handleSave}
						icon={<PlusOutlined />}>
						Add UOM
				</Button>
			</div>
		)
	}

    return (
		<>
			<PageTitle
				imagesrc={ItemImage}
				pageTitle="Units Of Measurement"
			/>
			{contextHolder}
			<CategoryAdd />
			{<div className='categoryList' style={{width: '850px'}}>
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={uomList.length === 0} size="large">
					<Form form={form} component={false}>
						<Table dataSource={uomList}
						    components={{
								body: {
								  cell: EditableCell,
								},
							}}
							bordered
							columns={mergedColumns}
							rowKey="symbol"
							rowClassName="editable-row"
							pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
							scroll={{ y: 500 }}
							size='small'
							style={{fontFamily: 'Poppins'}}/>
					</Form>
				</Spin>
			</div>}
		</>
	);
};

export default UOMList;