import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Popconfirm, Space, Spin, Table, Typography, notification } from 'antd';
import PageTitle from '../../common/PageTitle';
import CategoryImage from '../../../assets/images/category.png';
import columnSearch from '../../common/columnSearch';
import httpCall from '../../../utils/api';
import '../../../css/container.css';
import '../../../css/SmallComponents.css';
import '../../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import EditableCell from '../../common/EditableCell';

const CategoryList = () => {
    const navigate = useNavigate();
	const searchInput = useRef(null);
	const [categoryList, setCategoryList] = useState([]);
	const tableColumnSearch = columnSearch(searchInput);
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	const nameInputRef = useRef(null);
	
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const isEditing = (record) => record.category_id === editingKey;

	useEffect(() => {
		fetchCategoryList();
	}, []);

	const edit = (record) => {
		form.setFieldsValue({
			category_name: '',
			...record,
		});
		setEditingKey(record.category_id);
	};

	const cancel = () => {
		setEditingKey('');
	};

	const update = async (key) => {
		try {
			const row = await form.validateFields();
			const newData = [...categoryList];
			const index = newData.findIndex((item) => key === item.category_id);
			if (index > -1) {
				const item = newData[index];
				// Update API 
				handleUpdate(item.category_id, row.category_name);

				newData.splice(index, 1, {
					...item,
					...row,
				});
				setCategoryList(newData);
				setEditingKey('');
			} else {
				newData.push(row);
				setCategoryList(newData);
				setEditingKey('');
			}
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	const fetchCategoryList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/category/list',
		})
			.then((response) => {
				setLoading(false);
				if (response) {
					setCategoryList(response?.data);
				} else {
					console.error(
						'Error retrieving category list',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving category list', error);
			});
	};

    const openNotification = (msg, desc) => {
		api.open({
		  message: msg,
		  duration: 2,
		  description: desc,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleSave = () => {
		httpCall({
			method: 'POST',
			url: '/api/category/create',
			payload: {
				category_name: nameInputRef.current.input.value,
				description: '',
				status: 'Active',
				created_by: 1
			},
		})
		.then((response) => {
			if (response.data.success) {
				openNotification('Add Success !', 'New Category Added Successfully, Updating Grid. ');
				nameInputRef.current.value = "";
				fetchCategoryList();
			}
		})
		.catch((error) => {
			console.error(error);
		});
	};

	const handleUpdate = (category_id, category_name) => {
		httpCall({
			method: 'PUT',
			url: '/api/category/edit',
			payload: {
				category_name,
				category_id
			},
		})
		.then((response) => {
			if (response.data.success) {
				openNotification('Update Success !', 'Category Name Updated Successfully, Updating Grid. ');
			}
		})
		.catch((error) => {
			console.error(error);
		});
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/category/delete/'+ id,
		}).then(reponse => {
			openNotification('Delete Success !', 'Category Deleted Successfully, Refreshing Grid. ');
			fetchCategoryList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};

	const handleTotal = (total, range) => (
		<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
	);

    const columnsNew = [
		{
			title: 'Category Name',
			dataIndex: 'category_name',
			key: 'category_name',
			...tableColumnSearch('category_name'),
			align: 'center',
			editable: true,
			width: 300
		},		
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={() => update(record.category_id)}
							style={{
								marginRight: 8,
							}}
						> Save
						</Typography.Link>
						<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
							<a>Cancel</a>
						</Popconfirm>
					</span>
				) : (
					<Space size="middle" disabled={editingKey !== ''}>
						<EditOutlined
							style={{
								cursor: 'pointer',
								fontSize: '20px',
								color: '#08c',
							}}
							onClick={() => edit(record)}
						/>
						<Popconfirm
							title="Are you sure you want to delete?"
							onConfirm={() => handleDelete(record.category_id)}
						>
							<DeleteOutlined
								style={{ fontSize: '20px', color: '#FF6868' }}
							/>
						</Popconfirm>
					</Space>
				);}		
		  },
	];

	const mergedColumns = columnsNew.map((col) => {
		if (!col.editable) {
		  return col;
		}
		return {
		  ...col,
		  onCell: (record) => ({
			record,
			inputType: 'text',
			dataIndex: col.dataIndex,
			title: col.title,
			editing: isEditing(record),
		  }),
		};
	  });

	const CategoryAdd = () => {
		return (
			<div style={{ marginTop: '2rem', marginLeft: '2rem', width: '100%', display: 'flex', gap: '20px', justifyContent: 'start', alignItems: 'center' }}>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Category Name</span>
					<Input
						type="text"
						placeholder='Enter Category Name'							
						style={{ width: '400px', marginBottom: '30px' }}
						ref={nameInputRef}							
					/>
				</Space>
				<Button type='primary' 
						style={{backgroundColor: 'green'}} 
						onClick={handleSave}
						icon={<PlusOutlined />}>
						Add Category
				</Button>
			</div>
		)
	}

    return (
		<>
			<PageTitle
				imagesrc={CategoryImage}
				pageTitle="Category List"
				buttonList={[{
					buttonName: 'Back',
					className: 'btn-back',
					url: '/slj/items'
				}]}
			/>
			{contextHolder}
			<CategoryAdd />
			{<div className='categoryList' style={{width: '750px'}}>
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
					<Form form={form} component={false}>
						<Table dataSource={categoryList}
						    components={{
								body: {
								  cell: EditableCell,
								},
							}}
							bordered
							columns={mergedColumns}
							rowKey="category_id"
							rowClassName="editable-row"
							pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
							scroll={{ y: 500 }}
							size='small'
							style={{fontFamily: 'Poppins'}}/>
					</Form>
				</Spin>
			</div>}
		</>
	);
};

export default CategoryList;