import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Space, Spin, Table, Typography, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import ItemImage from '../../assets/images/item.png';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import EditableCell from '../common/EditableCell';

const Accounts = () => {
	const [accountList, setAccountList] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	const bankInputRef = useRef(null);
    const accountNoInputRef = useRef(null);
	const branchInputRef = useRef(null);
    const ifscInputRef = useRef(null);
	const addressInputRef = useRef(null);
	const [loading, setLoading] = useState(false);
	
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const isEditing = (record) => record.account_no === editingKey;

	useEffect(() => {
		fetchAccountList();
	}, []);

	const edit = (record) => {
		form.setFieldsValue({
			bank_name: '',
			branch: '',
			ifsc: '',
			address: '',
			...record,
		});
		setEditingKey(record.account_no);
	};

	const cancel = () => {
		setEditingKey('');
	};

	const update = async (key) => {
		try {
			const row = await form.validateFields();
			const newData = [...accountList];
			const index = newData.findIndex((item) => key === item.account_no);
			if (index > -1) {
				const item = newData[index];
				// Update API 
				handleUpdate(item.account_no, row.bank_name, row.branch, row.ifsc, row.address);

				newData.splice(index, 1, {
					...item,
					...row,
				});
				setAccountList(newData);
				setEditingKey('');
			} else {
				newData.push(row);
				setAccountList(newData);
				setEditingKey('');
			}
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	const fetchAccountList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/account/list',
		})
			.then((response) => {
				if (response) {
					setAccountList(response?.data);
				} else {
					console.error(
						'Error retrieving account list',
						response.message
					);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving account list', error);
				setLoading(false);
			});
	};

    const openNotification = (msg, desc) => {
		api.open({
		  message: msg,
		  duration: 2,
		  description: desc,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleSave = () => {
		httpCall({
			method: 'POST',
			url: '/api/account/create',
			payload: {
				bank_name: bankInputRef.current.input.value,
                account_no: accountNoInputRef.current.input.value,
				branch: branchInputRef.current.input.value,
				ifsc: ifscInputRef.current.input.value,
				address: addressInputRef.current.input.value,
			},
		})
		.then((response) => {
			if (response.data.success) {
				openNotification('Add Success !', 'New Account Added Successfully, Updating Grid. ');
				bankInputRef.current.value = "";
                accountNoInputRef.current.value = "";
				branchInputRef.current.value = "";
				ifscInputRef.current.value = "";
				addressInputRef.current.value = "";
				fetchAccountList();
			}
		})
		.catch((error) => {
			console.error(error);
		});
	};

	const handleUpdate = (account_no, bank_name, branch, ifsc, address) => {
		httpCall({
			method: 'PUT',
			url: '/api/account/update/' + account_no,
			payload: {
				bank_name,
				branch,
				ifsc,
				address
			},
		})
		.then((response) => {
			if (response.data.success) {
				openNotification('Update Success !', 'Account Updated Successfully, Updating Grid. ');
			}
		})
		.catch((error) => {
			console.error(error);
		});
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/account/delete/'+ id,
		}).then(reponse => {
			openNotification('Delete Success !', 'UOM Deleted Successfully, Refreshing Grid. ');
			fetchAccountList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};

	const handleTotal = (total, range) => (
		<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
	);

    const columnsNew = [
		{
			title: 'Bank',
			dataIndex: 'bank_name',
			key: 'bank_name',
			align: 'center',
			editable: true,
			width: 200
		},		
        {
			title: 'Account Number',
			dataIndex: 'account_no',
			key: 'account_no',
			align: 'center',
			width: 200
		},
		{
			title: 'IFSC',
			dataIndex: 'ifsc',
			key: 'ifsc',
			align: 'center',
			editable: true,
			width: 75
		},	
		{
			title: 'Branch Details',
			dataIndex: 'branch',
			key: 'branch',
			align: 'center',
			editable: true,
			width: 150
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			align: 'center',
			editable: true,
			width: 200
		},	
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={() => update(record.account_no)}
							style={{
								marginRight: 8,
							}}
						> Save
						</Typography.Link>
						<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
							<a>Cancel</a>
						</Popconfirm>
					</span>
				) : (
					<Space size="middle" disabled={editingKey !== ''}>
						<EditOutlined
							style={{
								cursor: 'pointer',
								fontSize: '20px',
								color: '#08c',
							}}
							onClick={() => edit(record)}
						/>
						<Popconfirm
							title="Are you sure you want to delete?"
							onConfirm={() => handleDelete(record.account_no)}
						>
							<DeleteOutlined
								style={{ fontSize: '20px', color: '#FF6868' }}
							/>
						</Popconfirm>
					</Space>
				);}		
		  },
	];

	const mergedColumns = columnsNew.map((col) => {
		if (!col.editable) {
		  return col;
		}
		return {
		  ...col,
		  onCell: (record) => ({
			record,
			inputType: 'text',
			dataIndex: col.dataIndex,
			title: col.title,
			editing: isEditing(record),
		  }),
		};
	  });

	const CategoryAdd = () => {
		return (
			<div style={{ marginTop: '2rem', marginLeft: '2rem', width: '100%', display: 'flex', gap: '20px', justifyContent: 'start', alignItems: 'center' }}>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Bank Name</span>
					<Input
						type="text"
						placeholder='Enter Bank Name'							
						style={{ width: '150px', marginBottom: '30px' }}
						ref={bankInputRef}							
					/>
				</Space>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Account Number</span>
					<Input
						type="text"
						placeholder='Enter Account No'							
						style={{ width: '200px', marginBottom: '30px' }}
						ref={accountNoInputRef}							
					/>
				</Space>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>IFSC</span>
					<Input
						type="text"
						placeholder='Enter IFSC Code'							
						style={{ width: '100px', marginBottom: '30px' }}
						ref={ifscInputRef}							
					/>
				</Space>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Branch</span>
					<Input
						type="text"
						placeholder='Enter Branch Details'							
						style={{ width: '250px', marginBottom: '30px' }}
						ref={branchInputRef}							
					/>
				</Space>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Address</span>
					<Input
						type="text"
						placeholder='Enter Address'							
						style={{ width: '250px', marginBottom: '30px' }}
						ref={addressInputRef}							
					/>
				</Space>
				<Button type='primary' 
						style={{backgroundColor: 'green'}} 
						onClick={handleSave}
						icon={<PlusOutlined />}>
						Add Account
				</Button>
			</div>
		)
	}

    return (
		<>
			<PageTitle
				imagesrc={ItemImage}
				pageTitle="Bank Accounts"
				buttonList={[{
					buttonName: 'Back',
					className: 'btn-back',
					url: '/slj/settings'
				}]}
			/>
			{contextHolder}
			<CategoryAdd />
			{<div className='categoryList' style={{width: '80%'}}>
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
					<Form form={form} component={false}>
						<Table dataSource={accountList}
						    components={{
								body: {
								  cell: EditableCell,
								},
							}}
							bordered
							columns={mergedColumns}
							rowKey="account_no"
							rowClassName="editable-row"
							pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
							size='small'
							style={{fontFamily: 'Poppins'}}/>
					</Form>
				</Spin>
			</div>}
		</>
	);
};

export default Accounts;