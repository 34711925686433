import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, DatePicker, Divider, Flex, Input, InputNumber, Row, Select, Space, Statistic, Table, notification } from 'antd';
import { CheckCircleFilled, DeleteFilled, PlusOutlined, ProfileOutlined, ProfileTwoTone } from '@ant-design/icons';
import SaleImage from '../../assets/images/sales.png';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

const {Option} = Select;

const PurchaseForm = () => { 
	const location = useLocation();
	const navigate = useNavigate();
    const [costCenterList, setCostCenterList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [itemDataList, setItemDataList] = useState([]);
    const [itemDataOptions, setItemDataOptions] = useState([]);
    const [custMobileOptions, setCustMobileOptions] = useState([]);
	const [api, contextHolder] = notification.useNotification();
    const [purchaseItemList, setPurchaseItemList] = useState([]);
    const [plants, setPlants] = useState([]);

    const formik = useFormik({
		initialValues: {
            purchase_order_no: '',
            vendor_id: '',
            sales_person_id: '',
            purchase_order_date: moment(),
            opg_pure: '',
            opg_cash: '',
            reference_no: '',
            bar_rate: '',
            thru: '',
            total_weight: '',
            plant_code: '',
            total_mc_amount: '',
            pending_mc_amount: ''
		},
		onSubmit: (values, {resetForm}) => {
			if(location?.state?.id) {
				updateSaleData(values);
			} else {
                let errorMsg = '';
                if(values.vendor_id === '') {
                    errorMsg = 'Please select a vendor by mobile';
                }
                else if(values.purchase_order_no === '') {
                    errorMsg = 'Please give purchase order number';
                }
                else if(values.plant_code === '') {
                    errorMsg = 'Please select a plant';
                }
                else if(values.sales_person_id === '') {
                    errorMsg = 'Please select a sales person';
                }
                else if(purchaseItemList.length === 0) {
                    errorMsg = 'Please add a purchase item';
                }
                if(errorMsg === '') {
                    createPurchaseData(values, resetForm);
                } else {
                    openErrorNotification(errorMsg);
                }
				
			}
		},
	});

    
    const purchaseItemFormik = useFormik({
		initialValues: {
            item_id: '',
            item_name: '',
            pcs: '',
            weight: '',
            touch: '',
            touch_unit: '%',
            pure: '',
            mc_amount: '',
            details: ''
		}
	});

    const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};

    useEffect(() => {
        fetchCostCenter();
        fetchPlantList();
        fetchCustomerList();
        fetchItemDataList();
		if(location?.state?.id) {
			httpCall({
				method: 'GET',
				url: '/api/purchase/get?id=' + location?.state?.id,
			})
				.then((response) => {
                    formik.setValues(response.data);                    
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, []);

    const calculateTotalWeight = () => {
        const totalWeight = purchaseItemList.reduce((acc, next) => acc + parseFloat(next.pure), 0);

        formik.setFieldValue('total_weight', totalWeight.toFixed(3));
    }

    const calculateTotalMCAmount = () => {
        const totalMCAmount = purchaseItemList.reduce((acc, next) => acc + parseFloat(next.mc_amount), 0);

        formik.setFieldValue('total_mc_amount', totalMCAmount.toFixed(3));
        formik.setFieldValue('pending_mc_amount', totalMCAmount.toFixed(3));
    }

    useEffect(() => {
        calculateTotalWeight();
        calculateTotalMCAmount();
	}, [purchaseItemList]);

    useEffect(() => {
        if(!!purchaseItemFormik.values.weight && !!purchaseItemFormik.values.touch) {
            const weight = parseFloat(purchaseItemFormik.values.weight || 0);
            let touch = parseFloat(purchaseItemFormik.values.touch || 0);
            const touch_unit = purchaseItemFormik.values.touch_unit;            
            const pure = touch_unit === '%' ? (weight * touch) / 100 : (weight * touch);            
            purchaseItemFormik.setFieldValue('pure', pure.toFixed(3));
        }
	}, [purchaseItemFormik.values.weight,
        purchaseItemFormik.values.touch, 
        purchaseItemFormik.values.touch_unit
    ]);

    const fetchCostCenter = () => {
		httpCall({
			method: 'GET',
			url: '/api/costcenter/get',
		})
			.then((response) => {
				if (response) {
                    setCostCenterList(response?.data.map(c => ({label: c.cost_center_name, value: c.cost_center_id})));
				} else {
					console.error(
						'Error retrieving categories data:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving categories data:', error);
			});
	};

    const fetchPlantList = () => {
		httpCall({
			method: 'GET',
			url: '/api/plant/list',
		})
			.then((response) => {
                setPlants(response?.data.map(p => ({label: p.plant_name, value: p.plant_code})))
			})
			.catch((error) => {
			});
	};

    const fetchCustomerList = () => {
		httpCall({
			method: 'GET',
			url: '/api/vendor/get',
		})
			.then((response) => {
				if (response) {
                    setVendorList(response?.data);
                    setCustMobileOptions(response?.data.map(c => ({label: c.mobile_number, value: c.mobile_number})))
				} else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving customer list:', error);
			});
	};

    const fetchItemDataList = () => {
		httpCall({
			method: 'GET',
			url: '/api/item/sale/list',
		})
			.then((response) => {
				if (response) {
                    setItemDataList(response?.data);
                    setItemDataOptions(response?.data.map(i => ({label: i.itemname, value: i.itemname})));
				} else {
					console.error(
						'Error retrieving item data list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving item data list:', error);
			});
	};

	const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

    const createPurchaseData = (values, handleReset) => {
        const purchase_order_date = moment(values.purchase_order_date).format('YYYY-MM-DD');
		httpCall({
			method: 'POST',
			url: '/api/purchase/purchaseOrder',
			payload: {...values, purchase_order_date, purchase_items: purchaseItemList}
		})
			.then((response) => {
				if (response) {
					openNotification('Purchase Order Created Successfully, Goto list view or Create another one. ');		
					handleReset();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const updateSaleData = (values) => {
		httpCall({
			method: 'PUT',
			url: '/api/purchase/edit',
			payload: {...values, itemid: location?.state?.id},
		})
			.then((response) => {
				if (response.data.success) {
					openNotification('Purchase Details Updated Successfully, Goto list view. ');		
					setTimeout(() => navigate('/slj/purchase'), 2500)
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const purchaseItemColumns = [
        {
			title: 'Item Name',
			dataIndex: 'item_name',
			key: 'item_name',
			align: 'center',
			width: 250
		},
        {
			title: 'Weight',
			dataIndex: 'weight',
			key: 'weight',		
			align: 'center',
			width: 150
		},
        {
			title: 'Touch',
			dataIndex: 'touch',
			key: 'touch',		
			align: 'center',
			width: 100,
            render: (_, record) => {
                return `${record.touch} (${record.touch_unit})`;
            }
		},
        {
			title: 'Pure',
			dataIndex: 'pure',
			key: 'pure',		
			align: 'center',
			width: 100
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
                (
                    <Space size="middle">
                        <DeleteFilled 
                            onClick={() => {
                                const idx = purchaseItemList.findIndex(item => item.item_id === record.item_id);
                                purchaseItemList.splice(idx, 1);
                                setPurchaseItemList([...purchaseItemList]);
                            }}
                            style={{cursor: 'pointer', fontSize: '20px', color: '#B31312'}}/>
                    </Space>
                )
		  },
	];

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const purchaseItemTable = (datasoure) => {
        return datasoure.length > 0 ? (
            <>
                <Row gutter={16} className="gutter-row sales-list">
                    <Col span={24} className="gutter-box">
                    <Table
						dataSource={datasoure}
						columns={purchaseItemColumns}
                        pagination={{position: ['bottomCenter'], pageSize: 2}}
						rowKey="item_name"
                        size='small'
                        bordered="true"						
						scroll={{ y: 100 }}
						style={{fontFamily: 'Poppins'}}/>
                    </Col>
                </Row>
            </>
        ) : null
    }

    const purchaseOrderForm = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row">
                    <Col span={10} className="gutter-box">
                        <div>
                            <span className="hintText">Vendor Mobile Number</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="mobile_number"
                            placeholder="Select Number"
                            style={{ width: '100%', height: '38px' }}
                            options={custMobileOptions}                            
                            onChange={(e)=> {
                                const vendor = vendorList.find(c => c.mobile_number === e);
                                formik.setFieldValue("vendor_id", vendor.id);
                                formik.setFieldValue("name", vendor.company_name);
                                formik.setFieldValue("address", vendor.address);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Purchase Date</span>                            
                        </div> 
                        <DatePicker
                            style={{ width: '100%', height: '38px' }}
                            disabled={true}
                            format={{
                                format: 'YYYY-MM-DD',
                                type: 'mask',
                            }}
                            value={formik.values.purchase_order_date}
                        />                             
                    </Col>
                    <Col span={4} offset={4} className="gutter-box">
                        <div>
                            <span className="hintText">Purchase Nr</span> 
                            <span className="mandatory"></span>                           
                        </div> 
                        <Input
                            type="text"
                            placeholder='Enter Purchase Nr'
                            id="purchase_order_no"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values['purchase_order_no']}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Vendor Name</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="name"
                            disabled={true}
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />     
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Vendor Address</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="address"
                            disabled={true}
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.address}
                        />                                
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Plant</span>  
                            <span className="mandatory"></span>                          
                        </div> 
                        <Select
                            id="plant_code"
                            placeholder="Select a Plant"
                            style={{ width: '100%', height: '38px' }}
                            options={plants}
                            onChange={(e)=> {
                                formik.setFieldValue("plant_code", e);
                            }}
                        />   
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={9} className="gutter-box">
                        <div>
                            <span className="hintText">Sales Person Name</span>  
                            <span className="mandatory"></span>                          
                        </div> 
                        <Select
                            id="sales_person_id"
                            placeholder="Select a Name"
                            style={{ width: '100%', height: '38px' }}
                            options={costCenterList}                            
                            onChange={(e)=> {
                                formik.setFieldValue("sales_person_id", e);
                            }}
                        />   
                    </Col>
                    <Col span={3} className="gutter-box">
                        <div>
                            <span className="hintText">OpgPure</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="opg_pure"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.opg_pure}
                        />    
                    </Col>
                    <Col span={3} className="gutter-box">
                        <div>
                            <span className="hintText">OpgCash</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="opg_cash"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.opg_cash}
                        />                             
                    </Col>
                    <Col span={3} className="gutter-box">
                        <div>
                            <span className="hintText">Reference No</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="reference_no"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.reference_no}
                        />                             
                    </Col>
                    <Col span={3} className="gutter-box">
                        <div>
                            <span className="hintText">Bar Rate</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="bar_rate"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.bar_rate}
                        />                             
                    </Col>
                    <Col span={3} className="gutter-box">
                        <div>
                            <span className="hintText">Thru</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="thru"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.thru}
                        />                             
                    </Col>
                </Row>
            </>
        )
    }

    const touchTypes = (form) => (
        <Select value={form.values.touch_unit} style={{ width: 60 }} onChange={(e) => form.setFieldValue("touch_unit", e)}>
          <Option value="g">g</Option>
          <Option value="%">%</Option>
        </Select>
    );

    const purchaseItemForm = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row">
                    <Col span={10} className="gutter-box">
                        <div>
                            <span className="hintText">Item Name</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="itemname"
                            placeholder="Select an Item"
                            style={{ width: '100%', height: '38px' }}
                            options={itemDataOptions}
                            value={purchaseItemFormik.values.item_name}
                            onChange={(e)=> {
                                purchaseItemFormik.setFieldValue("item_name", e);
                                const item = itemDataList.find(c => c.itemname === e);
                                purchaseItemFormik.setFieldValue("item_id", item.itemid);
                                purchaseItemFormik.setFieldValue("product", item.category_name);
                                purchaseItemFormik.setFieldValue("subproduct", item.sub_category_name);
                                purchaseItemFormik.setFieldValue("unit", item.unit);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Item Category</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="product"
                            disabled={true}
                            style={{ width: '100%'}}
                            onChange={purchaseItemFormik.handleChange}
                            value={purchaseItemFormik.values.product}
                        />     
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Item Sub Category</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="subproduct"
                            disabled={true}
                            style={{ width: '100%'}}
                            onChange={purchaseItemFormik.handleChange}
                            value={purchaseItemFormik.values.subproduct}
                        />                                
                    </Col>
                    <Col span={2} className="gutter-box">
                        <div>
                            <span className="hintText">Unit</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="unit"
                            disabled={true}
                            style={{ width: '100%'}}
                            onChange={purchaseItemFormik.handleChange}
                            value={purchaseItemFormik.values.unit}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={2} className="gutter-box">
                        <div>
                            <span className="hintText">PCS</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="pcs"
                            style={{ width: '100%'}}
                            onChange={purchaseItemFormik.handleChange}
                            value={purchaseItemFormik.values.pcs}
                        />    
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Weight</span>
                            <span className="mandatory"></span>                            
                        </div> 
                        <Input
                            type="text"
                            id="weight"
                            style={{ width: '100%'}}
                            onChange={purchaseItemFormik.handleChange}
                            value={purchaseItemFormik.values.weight}
                        />
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Touch</span>                                                
                        </div>                                                
                        <Input
                            type="text"
                            addonAfter={touchTypes(purchaseItemFormik)}
                            id="touch"
                            style={{ width: '100%'}}
                            onChange={purchaseItemFormik.handleChange}
                            value={purchaseItemFormik.values.touch}
                        />                                
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Pure</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="pure"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            onChange={purchaseItemFormik.handleChange}
                            value={purchaseItemFormik.values.pure}
                        />                                
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">MC Amount</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="mc_amount"
                            style={{ width: '100%'}}
                            onChange={purchaseItemFormik.handleChange}
                            value={purchaseItemFormik.values.mc_amount}
                        />                                
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Details</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="details"
                            style={{ width: '100%'}}
                            onChange={purchaseItemFormik.handleChange}
                            value={purchaseItemFormik.values.details}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '20px 0px'}}>
                    <Col span={7} className="gutter-box" style={{textAlign: 'center'}}>
                        <Button 
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => {
                                const purchaseItem = {                                    
                                    item_id: purchaseItemFormik.values.item_id,
                                    item_name: purchaseItemFormik.values.item_name,
                                    pcs: purchaseItemFormik.values.pcs,
                                    weight: purchaseItemFormik.values.weight,
                                    touch: purchaseItemFormik.values.touch,
                                    touch_unit: purchaseItemFormik.values.touch_unit,
                                    pure: purchaseItemFormik.values.pure,
                                    mc_amount: purchaseItemFormik.values.mc_amount,
                                    details: purchaseItemFormik.values.details,
                                }
                                if(purchaseItem.item_name !== '' && purchaseItem.pcs !== '' && purchaseItem.weight !== '' && purchaseItem.touch !== '') {
                                    setPurchaseItemList([...purchaseItemList, purchaseItem]);
                                    purchaseItemFormik.resetForm();
                                    purchaseItemFormik.setFieldValue('item_name', '')
                                } else {
                                    openErrorNotification('Please Enter Required Item Fields.');
                                }                             
                            }}
                            style={{ width: '100%', height: '36px', fontFamily: 'poppins', fontSize: '15px'}}>
                                ADD PURCHASE ITEM
                        </Button>
                    </Col>                  
                </Row>
                {purchaseItemTable(purchaseItemList)}
            </>
        )
    }

    const createNewFormSection = () => {
		return (
			<>
				{purchaseOrderForm()}

				<Collapse
                    style={{marginTop: '30px'}} 
                    defaultActiveKey={['1']} 
                    items={[
                        {
                            key: '1',
                            label: 'PURCHASE ITEM DETAILS',
                            children: purchaseItemForm(),
                        }
                    ]}>
                </Collapse>
                <Card style={{marginTop: '35px'}}>
                    <Row gutter={16} className="gutter-row">
                        <Col span={8} offset={16} className="gutter-box" style={{display: 'flex', justifyContent: 'end'}}>
                            <Statistic
                                title="Total Weight (g)" 
                                value={formik.values.total_weight} 
                                precision={3}
                                style={{textAlign:'right', border: '1px dotted #d3d3d3', padding: '10px'}}
                                valueStyle={{ width: '275px', fontSize: '28px', textAlign:'right', color: '#000000', fontWeight: 'bolder'}} />
                        </Col>                             
                    </Row>
                </Card>
			</>
		);
	};

	return (
		<>
		<PageTitle
			imagesrc={SaleImage}
			pageTitle="Create Purchase Entry"
			buttonList={[{
				buttonName: 'Back',
				className: 'btn-back',
				url: '/slj/purchase'
			}]}
		/>
		{contextHolder}
			<div className="content-area">
				<div className="formAreaStyle">
					{ createNewFormSection() }		
				</div>
			</div>
			<div className="formSubmitArea">
				<div className="formSubmitInnerArea">
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: 'grey' }}
						onClick={formik.handleReset}
					>
						Reset Fields
					</Button>
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: '#1f43e5' }}
						onClick={formik.handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
		</>
	);


}

export default PurchaseForm;