import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, InputNumber, Row, Select, notification } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import ItemImage from '../../assets/images/item.png';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';

const Payment = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [api, contextHolder] = notification.useNotification();

    const formikCash = useFormik({
		initialValues: {
            active: '' 
		},
	});
    const formikUPI = useFormik({
		initialValues: {
            active: ''  
		},
	});
    const formikCard = useFormik({
		initialValues: {
            active: '' 
		},
	});
    const formikBanking = useFormik({
		initialValues: {
            active: ''
		},
	});

    const handleSubmit = () => {
        const options = [];
        options.push({mop: 'Cash', ...formikCash.values});
        options.push({mop: 'UPI', ...formikUPI.values});
        options.push({mop: 'Card', ...formikCard.values});
        options.push({mop: 'Banking', ...formikBanking.values});
        updateItemData(options);        
    }

	useEffect(() => {        
        fetchPaymentOptions();
	}, []);

    const setValues = (form, field) => {
        form.setFieldValue('active', field.active);
    }

    const fetchPaymentOptions = () => {
		httpCall({
			method: 'GET',
			url: '/api/payment/list',
		})
			.then((response) => {
                for (let index = 0; index < response?.data.length; index++) {
                    const field = response?.data[index];
                    switch(field.mop) {
                        case 'Cash':
                            setValues(formikCash, field);
                            break;
                        case 'UPI':
                            setValues(formikUPI, field);
                            break;
                        case 'Card':
                            setValues(formikCard, field);
                            break;
                        case 'Banking':
                            setValues(formikBanking, field);
                            break;
                        default:
                            break;
                    }
                }                
			})
			.catch((error) => {
				console.error('Error retrieving payment options:', error);
			});
	};

    const updateItemData = (values) => {
		httpCall({
			method: 'PUT',
			url: '/api/payment/edit',
			payload: values,
		})
        .then((response) => {
            if (response.data.success) {
                openNotification('Payment Options Info Updated Successfully.. ');
            }
        })
        .catch((error) => {
            console.error(error);
        });
	};

	const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

    const createNewFormSection = () => {
		return (
			<>
				<Row gutter={16} className="gutter-row" style={{border: '1px solid #d3d3d3', backgroundColor: '#d3d3d3'}}>
                    <Col span={10} style={{textAlign: 'center'}} className="gutter-box payment-mode-h-row">
                        <span>Enable/Disable</span>
                    </Col>
                    <Col span={14} style={{textAlign: 'center'}} className="gutter-box payment-mode-h-row">
                        <span>Mode Of Payment</span>
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row" style={{marginTop: '10px', border: '1px dotted #d3d3d3'}}>
                    <Col span={10} style={{textAlign: 'center'}} className="gutter-box payment-mode-row">
                        <Checkbox id="active" checked={formikCash.values.active} onChange={formikCash.handleChange}></Checkbox>                            
                    </Col>
                    <Col span={14} style={{textAlign: 'center'}} className="gutter-box payment-mode-row">
                        <span>Cash</span>
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row" style={{marginTop: '10px', border: '1px dotted #d3d3d3'}}>
                    <Col span={10} style={{textAlign: 'center'}} className="gutter-box payment-mode-row">
                        <Checkbox id="active" checked={formikUPI.values.active} onChange={formikUPI.handleChange}></Checkbox>                                           
                    </Col>
                    <Col span={14} style={{textAlign: 'center'}} className="gutter-box payment-mode-row">
                        <span>UPI</span>
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row" style={{marginTop: '10px', border: '1px dotted #d3d3d3'}}>
                    <Col span={10} style={{textAlign: 'center'}} className="gutter-box payment-mode-row">
                        <Checkbox id="active" checked={formikCard.values.active} onChange={formikCard.handleChange}></Checkbox>                            
                    </Col>
                    <Col span={14} style={{textAlign: 'center'}} className="gutter-box payment-mode-row">
                        <span>Card Payment</span>
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row" style={{marginTop: '10px', border: '1px dotted #d3d3d3'}}>
                    <Col span={10} style={{textAlign: 'center'}} className="gutter-box payment-mode-row">
                        <Checkbox id="active" checked={formikBanking.values.active} onChange={formikBanking.handleChange}></Checkbox>                            
                    </Col>
                    <Col span={14} style={{textAlign: 'center'}} className="gutter-box payment-mode-row">
                        <span>Banking</span>
                    </Col>
                </Row>
                 
			</>
		);
	};

	return (
		<>
		<PageTitle
			imagesrc={ItemImage}
			pageTitle="Edit Payment Options"
            buttonList={[{
                buttonName: 'Back',
                className: 'btn-back',
                url: '/slj/settings'
            }]}
		/>
		{contextHolder}
			<div className="content-area">
				<div className="formAreaStyle">
					<div style={{width: '30%'}}>
                    { createNewFormSection() }
                    </div>
				</div>
			</div>
			<div className="formSubmitArea">
				<div className="formSubmitInnerArea">
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: 'grey' }}
						// onClick={formik.handleReset}
					>
						Reset Fields
					</Button>
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: '#1f43e5' }}
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
		</>
	);
};

export default Payment;
