import Logo from '../assets/images/logo.png';
import EyeShow from '../assets/images/eye-show.svg';
import EyeHide from '../assets/images/eye-hide.svg';
import '../css/Login.css';
import { useRef, useState } from 'react';
import httpCall from '../utils/api';
import { useNavigate } from 'react-router-dom';

const Login = () => {
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const statusRef = useRef(null);

	const username = useRef('');
	const password = useRef('');

	const handleSubmit = (e) => {
		e.preventDefault();

		invokeLogin();
	}

	const invokeLogin = async () => {
		try {
			const loginResponse = await httpCall({
				url: '/api/login',
				method: 'POST',
				payload: { username: username.current.value, password: password.current.value }				
			});
			localStorage.setItem('AuthToken', loginResponse?.data?.token);
			updateStatusMsg(true);
			navigate('/slj');
		} catch (error) {
			updateStatusMsg(false, error);
		}
	}

	const updateStatusMsg = (isSuccess, error) => {
		const status = statusRef.current;
		if(isSuccess) {
			status.className = 'successText';
			status.textContent = 'Logging in...';
		} else {
			status.className = 'errorText';
			status.textContent = error?.response?.data?.message;
		}
	}

	return (
		<>
			<div className="container background">
				<form className="loginColumn" onSubmit={handleSubmit}>
					<img
						className="loginPageLogo"
						src={Logo}
						alt="Company logo"
					/>
					<span className="loginHeader">Login</span>
					<input
						ref={username}
						className="loginInput"
						type="text"
						name="username"
						placeholder="Username"
					/>
					<br />
					<div style={{ position: 'relative' }}>
						<input
							ref={password}
							className="loginInput"
							type={showPassword ? 'text' : 'password'}
							placeholder="Password"
						/>
						<img
							src={showPassword ? EyeShow : EyeHide}
							className="eyeIcon"
							width="20px"
							height="20px"
							alt="eyeIcon"
							title="Show/Hide Password"
							onClick={() => setShowPassword(!showPassword)}
						/>
					</div>
					<p ref={statusRef} className=""></p>
					<button className="loginButton" type="submit">
						Login
					</button>
				</form>
			</div>
		</>
	);
};

export default Login;
