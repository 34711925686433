import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, Row, notification } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import CustomerImage from '../../assets/images/customer.png';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import customerFormFields from './customerFormFields';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomerForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [api, contextHolder] = notification.useNotification();

    const openErrorNotification = (description) => {
        api.error({
            message: 'Error !',
            duration: 4,
            description: description,
        });
    };

    const formik = useFormik({
        initialValues: {
            company_name: '',
            mailing_name: '',
            address: '',
            country: '',
            state: '',
            city: '',
            zip_code: '',
            website: '',
            pan_card_no: '',
            gst_register_type: '',
            gst_no: '',
            email: '',
            mobile_number: '',
            phone_number: '',
            price_type: '',
            credit_amount_limit: '',
            credit_days: '',
            customer_type: '',
            parent_company: '',
            assigned_to: '',
            contact_name: '',
            designation: '',
            department: '',
            contact_mobile_number: '',
            contact_email: '',
            experience: '',
            education_details: '',
            account_holder_name: '',
            account_no: '',
            confirm_account_no: '',
            ifsc_code: '',
            bank_name: '',
            branch: '',
            consignor_company_name: '',
            delivery_gst_no: '',
            delivery_gst_register_type: '',
            delivery_address: '',
            delivery_country: '',
            delivery_state: '',
            delivery_city: '',
            delivery_zip_code: '',
            delivery_contact_name: '',
            contact_designation: '',
            contact_department: '',
            contact_mobile_no: '',
            delivery_contact_email: '',
            delivery_contact_mobile_no: ''
        },
        validate: (values) => {
            const errors = {};

            // Validate mobile number!
            if (values.mobile_number && !/^\d{10}$/.test(values.mobile_number)) {
                errors.mobile_number = 'Mobile number must be exactly 10 digits';
            }

            // Validate phone number
            if (values.phone_number && !/^\d{10}$/.test(values.phone_number)) {
                errors.phone_number = 'Phone number must be exactly 10 digits';
            }

            // Validate contact mobile number
            if (values.contact_mobile_number && !/^\d{10}$/.test(values.contact_mobile_number)) {
                errors.contact_mobile_number = 'Mobile number must be exactly 10 digits';
            }

            // Validate delivery contact mobile number
            if (values.delivery_contact_mobile_no && !/^\d{10}$/.test(values.delivery_contact_mobile_no)) {
                errors.delivery_contact_mobile_no = 'Mobile number must be exactly 10 digits';
            }

            return errors;
        },
        onSubmit: (values, { resetForm }) => {
            if (values.mobile_number === '' || values.company_name === '') {
                openErrorNotification('Please Enter Required Fields...');
                return;
            }
            if (location?.state?.id) {
                updateCostCenter(values);
            } else {
                createVendor(values, resetForm);
            }
        },
    });

    useEffect(() => {
        if (location?.state?.id) {
            httpCall({
                method: 'GET',
                url: '/api/customer/getCustomerData?id=' + location?.state?.id,
            })
                .then((response) => {
                    formik.setValues(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [location?.state?.id]);

    useEffect(() => {
        formik.setFieldValue('mailing_name', formik.values.company_name);
    }, [formik.values.company_name]);

    const openNotification = (description) => {
        api.open({
            message: 'Success !',
            duration: 2,
            description: description,
            icon: <CheckCircleFilled style={{ color: '#808000' }} />,
        });
    };

    const createVendor = (values, handleReset) => {
        httpCall({
            method: 'POST',
            url: '/api/customer/create',
            payload: values,
        })
            .then((response) => {
                if (response) {
                    openNotification('Customer Created Successfully, Goto list view or Create another one.');
                    handleReset();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const updateCostCenter = (values) => {
        httpCall({
            method: 'PUT',
            url: '/api/customer/edit',
            payload: { ...values, customerId: location?.state?.id },
        })
            .then((response) => {
                if (response) {
                    openNotification('Customer Details Updated Successfully, Goto list view.');
                    setTimeout(() => navigate('/slj/customer'), 2500);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const createNewFormSection = (sections) => {
        return (
            <>
                {sections.map((section, index) => (
                    <Row gutter={16} className="gutter-row" key={index}>
                        {section.map((field, index) => (
                            <Col span={field.colSpan} className="gutter-box" key={index}>
                                <div>
                                    <span className="hintText">{field.label}</span>
                                    {field.isRequired && (
                                        <span className="mandatory"></span>
                                    )}
                                </div>

                                {field.type === 'text' ? (
                                    <Input
                                        type={field.id.includes('mobile') || field.id.includes('phone') ? 'tel' : 'text'}
                                        id={field.id}
                                        style={{ width: '100%' }}
                                        disabled={field.disabled}
                                        onChange={formik.handleChange}
                                        onKeyPress={(e) => {
                                            if (field.id.includes('mobile') || field.id.includes('phone')) {
                                                if (!/^\d*$/.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }
                                        }}
                                        value={formik.values[field.id]}
                                    />
                                ) : (
                                    <Checkbox
                                        id={field.id}
                                        style={{ width: '100%' }}
                                        onChange={formik.handleChange}
                                        checked={formik.values[field.id]}
                                    />
                                )}
                                {formik.touched[field.id] && formik.errors[field.id] ? (
                                    <div>
                                        <span
                                            style={{
                                                fontFamily: 'Poppins',
                                                fontSize: '12px',
                                                color: 'red',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            {formik.errors[field.id]}
                                        </span>
                                    </div>
                                ) : null}
                            </Col>
                        ))}
                    </Row>
                ))}
            </>
        );
    };

    return (
        <>
            <PageTitle
                imagesrc={CustomerImage}
                pageTitle="Create Customer"
                buttonList={[{
                    buttonName: 'Back',
                    className: 'btn-back',
                    url: '/slj/customer'
                }]}
            />
            {contextHolder}
            <div className="content-area">
                <div className="formAreaStyle">
                    {createNewFormSection(
                        customerFormFields.section1
                    )}
                    <div className="DetailsBox">
                        <div className="Tabs">
                            <div
                                className={`individualTab ${activeTab === 1 ? 'active' : ''
                                    }`}
                                onClick={() => setActiveTab(1)}
                            >
                                Contact Details
                            </div>
                            <div
                                className={`individualTab ${activeTab === 2 ? 'active' : ''
                                    }`}
                                onClick={() => setActiveTab(2)}
                            >
                                Bank Details
                            </div>
                            <div
                                className={`individualTab ${activeTab === 3 ? 'active' : ''
                                    }`}
                                onClick={() => setActiveTab(3)}
                            >
                                Delivery Address
                            </div>
                        </div>
                        {activeTab === 1 && (
                            <div className="individualTabDetails">
                                {createNewFormSection(
                                    customerFormFields.section2
                                )}
                            </div>
                        )}
                        {activeTab === 2 && (
                            <div className="individualTabDetails">
                                {createNewFormSection(
                                    customerFormFields.section3
                                )}
                            </div>
                        )}
                        {activeTab === 3 && (
                            <div className="individualTabDetails">
                                {createNewFormSection(
                                    customerFormFields.section4
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <Button
                        type="primary"
                        size="large"
                        style={{ width: '170px', backgroundColor: 'grey' }}
                        onClick={formik.handleReset}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        style={{ width: '170px', backgroundColor: '#1B3A61' }}
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CustomerForm;
