import React from 'react';
import Category from '../../assets/images/category.png';
import Item from '../../assets/images/item.png';
import '../../css/item.css';
import { useNavigate } from 'react-router-dom';
import { Avatar, Card, Space } from 'antd';
import Meta from 'antd/es/card/Meta';
import { OrderedListOutlined, PlusOutlined } from '@ant-design/icons';

const ItemMenu = () => {
	const navigate = useNavigate();

	const itemMenuParams = [
		{
			menu: 'Category',
			description: 'Item Main Category Actions',
			route: '/slj/category',
			icon: Category,
		},
		{
			menu: 'Sub Category',
			description: 'Item Sub Category Actions',
			route: '/slj/subcategory',
			icon: Category,
		},
		{
			menu: 'Item',
			description: 'Item Data Actions',
			route: '/slj/item-data',
			icon: Item,
		},
		{
			menu: 'Item Type',
			description: 'Item Type Actions',
			route: '/slj/item-type',
			icon: Item,
		},
	];

	return (
		<React.Fragment>
			<div className="detailsContainer">
				<span
					className="itemImagesGrid"
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						maxWidth: '80%',
					}}
				>
					{itemMenuParams.map((obj, i) => (
						<Card key={i} style={{ width: 300, cursor: 'pointer' }} onClick={() => navigate(obj.route)} actions={[
								<Space size="middle" onClick={() => navigate(obj.route)}>
									<OrderedListOutlined
										style={{ fontSize: '18px' }}
										key="list"
									/>
									<span>List</span>
								</Space>,
								<Space size="middle" onClick={() => navigate(obj.route + '/create')}>
									<PlusOutlined
										style={{ fontSize: '18px' }}
										key="add"
									/>
									<span>Create</span>
								</Space>,
							]}
						>
							<Meta
							  avatar={
									<Avatar shape="square" src={obj.icon} />
								}
								title={obj.menu}
								description={obj.description}
							/>
						</Card>
					))}
				</span>
			</div>
		</React.Fragment>
	);
};

export default ItemMenu;
