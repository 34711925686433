import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Menu.css';
import ImageLogo from '../assets/images/enterot-logo.png';
import ImageLogoText from '../assets/images/enterot-logo-text.png';
import ImageMaster from '../assets/images/master.png';
import ImageCostCenter from '../assets/images/cost-center.png';
import ImageReports from '../assets/images/reports.png';
import ImageStockTransfer from '../assets/images/stock-transfer.png';
import ImageEmployee from '../assets/images/employee.png';
import ImageMenuRightArw from '../assets/images/right-arrow.png';
import ImageMenuLeftArw from '../assets/images/left-arrow.png';
import ImageSales from '../assets/images/sales.png';
import ImageRole from '../assets/images/role.png';
import ImageRoleCtrl from '../assets/images/role-ctrl.png';
import ImageScreenAccessCtrl from '../assets/images/screen-access-ctrl.png';
import ImageItemMenu from '../assets/images/item_menu.png';
import ImageUOM from '../assets/images/uom.png';
import ImageGoldSmith from '../assets/images/gold-smith.png';
import ImageVendor from '../assets/images/vendor.png';
import ImageInventory from '../assets/images/inventory.png';
import ImageCustomer from '../assets/images/customer.png';
import ImagePurchase from '../assets/images/purchase.jpg';
import ImageTouchMap from '../assets/images/touch-mapping.png';

const MenuItem = ({ title, submenus, icon, ismCollapsed, clickedSubmenuIndices, setClickedSubmenuIndices }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isClicked, setIsClicked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const menuStorageKey = `menuCollapsed_${title}`;
    const storedIsCollapsed = sessionStorage.getItem(menuStorageKey);
    if (storedIsCollapsed !== null) {
      setIsCollapsed(storedIsCollapsed === 'true');
      setIsClicked(storedIsCollapsed !== 'true');
    }
  }, [title]);

  const toggleCollapse = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    setIsClicked(!newCollapsedState);
    const menuStorageKey = `menuCollapsed_${title}`;
    sessionStorage.setItem(menuStorageKey, newCollapsedState.toString());
  };

  const handleSubmenuClick = (submenuIndex, submenuName) => {
    setClickedSubmenuIndices(submenuIndex);
    navigate(`/slj/${submenuName}`);
  };

  return (
    <div className="menu-list">
      <div
        className={`menu-item-header ${isClicked ? 'c' : ''}`}
        onClick={toggleCollapse}
      >
        <img className="icon" src={icon} alt={title} />
        {!ismCollapsed && <span className="menuTextSelected"> {title}</span>}
      </div>
      {!isCollapsed && (
        <div className={`menu-item-content ${ismCollapsed ? 'collapsed' : ''}`}>
          {submenus.map((submenu, index) => (
            <div
              className={`menu-sub ${
                clickedSubmenuIndices === submenu.index ? 'submenu-clicked' : ''
              }`}
              onClick={() => handleSubmenuClick(submenu.index, submenu.path)}
              key={submenu.index}
            >
              {!isCollapsed && (
                <img
                  className={`icon-sub ${
                    ismCollapsed && clickedSubmenuIndices === submenu.index
                      ? 'c'
                      : 'a'
                  }`}
                  src={submenu.icon}
                  alt={submenu.name}
                />
              )}
              {!ismCollapsed && (
                <span key={submenu.index} className="submenu">
                  {submenu.name}
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const menuItemsData = [
  {
    title: 'Master',
    submenus: [
      { name: 'Cost Center', path:'cost-center', icon: ImageCostCenter, index: '1.1' },
      { name: 'Employee', path:'employee', icon: ImageEmployee, index: '1.2'  },
      { name: 'Role', path:'role', icon: ImageRole, index: '1.3'  },
      { name: 'Role Control', path:'role-control', icon: ImageRoleCtrl, index: '1.4'  },
      { name: 'Access Control', path:'access-control', icon: ImageScreenAccessCtrl, index: '1.5'  },
      { name: 'Item', path:'items', icon: ImageItemMenu, index: '1.6'  },
      { name: 'UOM', path:'uom', icon: ImageUOM, index: '1.7'  },
      { name: 'Gold Smith', path:'goldsmith', icon: ImageGoldSmith, index: '1.8'  },
      { name: 'Vendor', path:'vendor', icon: ImageVendor, index: '1.9'  },
      { name: 'Customer', path:'customer', icon: ImageCustomer, index: '1.10'  },
      { name: 'Touch Mapping', path:'touch-mapping', icon: ImageTouchMap, index: '1.11'  },
      { name: 'Plant', path:'plant', icon: ImageTouchMap, index: '1.12'  },
      { name: 'Settings', path:'settings', icon: ImageTouchMap, index: '1.13'  },
    ],
    icon: ImageMaster,
  },
  {
    title: 'Transaction',
    submenus: [
      { name: 'Quotation', path:'quotation', icon: ImageSales, index: '2.1'  },
      { name: 'Estimate', path:'sales', icon: ImageSales, index: '2.2'  },
      { name: 'Customer Receipt', path:'sales-receipt', icon: ImageSales, index: '2.3'  },
      { name: 'Purchase', path:'purchase', icon: ImagePurchase, index: '2.4'  },
      { name: 'Vendor Payment', path:'purchase-receipt', icon: ImagePurchase, index: '2.5'  },
      { name: 'MC Payment', path:'mc-receipt', icon: ImagePurchase, index: '2.6'  },
      { name: 'Inventory', path:'stock', icon: ImageInventory, index: '2.7'  },
      { name: 'Stock Transfer', path:'stock-transfer', icon: ImageStockTransfer, index: '2.8'  },      
    ],
    icon: ImageStockTransfer,
  },
  {
    title: 'Report',
    submenus: [
      { name: 'Sales Report', path:'sales-report', icon: ImageSales, index: '3.1'  },
      { name: 'Purchse Report', path:'purchase-report', icon: ImagePurchase, index: '3.2'  },     
    ],
    icon: ImageReports,
  },
];

const Menu = () => {
  const [ isMenuCollapsed, setIsMenuCollapsed ] = useState(false);
  const [clickedSubmenuIndices, setClickedSubmenuIndices] = useState('');
  const toggleMenuCollapse = () => {
	setIsMenuCollapsed(!isMenuCollapsed);
  }
  const isLoggedIn = true;

  const navigate = useNavigate();

  if (isLoggedIn) {
    return (
      <div className={`menu ${isMenuCollapsed ? 'collapsed' : ''}`}>
        <div className="menu-top">
          {!isMenuCollapsed ? (
            <React.Fragment>
              <div className="logo-container">
                <img
                  alt="enterot"
                  className="companyLogo"
                  src={ImageLogo}
                  style={{ paddingRight: '5px' }}
                  onClick={() => navigate('/menu')}
                />
                <img
                  alt="enterot"
                  className="companyLogoText"
                  src={ImageLogoText}
                  onClick={() => navigate('/menu')}
                />
              </div>
            </React.Fragment>
          ) : null}
          <div className="menu-toggle" onClick={toggleMenuCollapse}>
            <img
              alt="toggle"
              className="menuImage"
              src={!isMenuCollapsed ? ImageMenuLeftArw : ImageMenuRightArw}
            />
          </div>
        </div>

        <div className="menu-items">
          {menuItemsData.map((menuItem, index) => (
            <MenuItem
              key={index}
              title={menuItem.title}
              submenus={menuItem.submenus}
              icon={menuItem.icon || undefined}
              ismCollapsed={isMenuCollapsed}
              clickedSubmenuIndices={clickedSubmenuIndices}
              setClickedSubmenuIndices={setClickedSubmenuIndices}
            />
          ))}
        </div>
      </div>
    );
  } else {
    navigate('/');
  }
};

export default Menu;
