import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Skeleton, Space, Spin, Table, Tag, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import CostCenterImage from '../../assets/images/cost-center.png';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, FilterFilled } from '@ant-design/icons';

const CostCenterList = () => {
	const navigate = useNavigate();
	const searchInput = useRef(null);
	const [costCenterData, setCostCenterData] = useState([]);
	const tableColumnSearch = columnSearch(searchInput);
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		setLoading(true);
		fetchCostCenterData();
	}, []);

	const fetchCostCenterData = () => {
		httpCall({
			method: 'GET',
			url: '/api/costcenter/get',
		})
			.then((response) => {
				setLoading(false);
				if (response) {
					setCostCenterData(response?.data);
				} else {
					console.error(
						'Error retrieving cost center data:',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving cost center data:', error);
			});
	};

	const openNotification = () => {
		api.open({
		  message: 'Delete Success !',
		  duration: 2,
		  description:
			'Cost Center Deleted Successfully, Refreshing Grid. ',
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleEdit = (id) => {
		navigate('/slj/cost-center/form', { state: { id } })
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/costcenter/delete/'+ id,
		}).then(reponse => {
			openNotification();
			fetchCostCenterData();
		}).catch(error => {
			console.log('Error>', error)
		})
	};
  
  	const columnsNew = [
		{
			title: 'Name',
			dataIndex: 'cost_center_name',
			key: 'cost_center_name',
			...tableColumnSearch('cost_center_name'),
			align: 'center',
			width: 300
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
			align: 'center',
			width: 150
		},
		{
			title: 'Status',
			dataIndex: 'active',
			key: 'active',
			align: 'center',
			width: 150,
			filters: [
				{
				  text: 'Active',
				  value: true,
				},
				{
					text: 'InActive',
					value: false,
				},
			],
			onFilter: (value, record) => record.active === value,
			filterIcon: (filtered) => (
				<FilterFilled
					style={{
						color: filtered ? '#1677ff' : undefined,
						fontSize: '16px'
					}}
				/>
			),
			render: (_, record) => 
				costCenterData.length >= 1 ? (
						<Space size="middle">
							{
								record.active ? 
								<Tag color="success">Active</Tag> :
								<Tag color="red">InActive</Tag>
							}
						</Space>
				) : null	
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
				costCenterData.length >= 1 ? (
						<Space size="middle">
							<EyeOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>
							<EditOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}} onClick={() => handleEdit(record.cost_center_id)} />
							<Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.cost_center_id)}>
								<DeleteOutlined style={{fontSize: '20px', color: '#FF6868'}}/>
							</Popconfirm>
						</Space>
				) : null			
		  },
	];

	return (
		<>
			<PageTitle
				imagesrc={CostCenterImage}
				pageTitle="Cost Center List"
				buttonList={[{
					buttonName: 'Create Cost Center',
					className: 'btn-create',
					url: '/slj/cost-center/form'
				}]}
			/>
			{contextHolder}
			{<div className='vendorListArea'>
			 	<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
					<Table 
						dataSource={costCenterData}
						columns={columnsNew}
						rowKey="cost_center_name"
						pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
						scroll={{ y: 570 }}
						style={{fontFamily: 'Poppins'}}/>
				</Spin>
			</div>}
		</>
	);
};

const handleTotal = (total, range) => (
	<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
);

export default CostCenterList;
