import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Popconfirm, Space, Spin, Table, Typography, notification } from 'antd';
import PageTitle from '../../common/PageTitle';
import ItemImage from '../../../assets/images/item.png';
import columnSearch from '../../common/columnSearch';
import httpCall from '../../../utils/api';
import '../../../css/container.css';
import '../../../css/SmallComponents.css';
import '../../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import EditableCell from '../../common/EditableCell';

const ItemTypeList = () => {
    const navigate = useNavigate();
	const searchInput = useRef(null);
	const [itemTypeList, setItemTypeList] = useState([]);
	const tableColumnSearch = columnSearch(searchInput);
	const [api, contextHolder] = notification.useNotification();
	const [isLoading, setIsLoading] = useState(false);
	const nameInputRef = useRef(null);
    const rateInputRef = useRef(null);
	
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const isEditing = (record) => record.name === editingKey;

	useEffect(() => {
		fetchItemTypeList();
	}, []);

	const edit = (record) => {
		form.setFieldsValue({
			name: '',
			...record,
		});
		setEditingKey(record.name);
	};

	const cancel = () => {
		setEditingKey('');
	};

	const update = async (key) => {
		try {
			const row = await form.validateFields();
			const newData = [...itemTypeList];
			const index = newData.findIndex((item) => key === item.name);
			if (index > -1) {
				const item = newData[index];
				// Update API 
				handleUpdate(item.name, row.current_rate);

				newData.splice(index, 1, {
					...item,
					...row,
				});
				setItemTypeList(newData);
				setEditingKey('');
			} else {
				newData.push(row);
				setItemTypeList(newData);
				setEditingKey('');
			}
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	const fetchItemTypeList = () => {
		setIsLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/itemtype/list',
		})
			.then((response) => {
				setIsLoading(false);
				if (response) {
					setItemTypeList(response?.data);
				} else {
					console.error(
						'Error retrieving category list',
						response.message
					);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.error('Error retrieving item type list', error);
			});
	};

    const openNotification = (msg, desc) => {
		api.open({
		  message: msg,
		  duration: 2,
		  description: desc,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleSave = () => {
		httpCall({
			method: 'POST',
			url: '/api/itemtype/create',
			payload: {
				name: nameInputRef.current.input.value,
                current_rate: rateInputRef.current.input.value,
			},
		})
		.then((response) => {
			if (response.data.success) {
				openNotification('Add Success !', 'New Type Added Successfully, Updating Grid. ');
				nameInputRef.current.value = "";
                rateInputRef.current.value = "";
				fetchItemTypeList();
			}
		})
		.catch((error) => {
			console.error(error);
		});
	};

	const handleUpdate = (name, current_rate) => {
		httpCall({
			method: 'PUT',
			url: '/api/itemtype/edit',
			payload: {
				name,
				current_rate
			},
		})
		.then((response) => {
			if (response.data.success) {
				openNotification('Update Success !', 'Item Type Updated Successfully, Updating Grid. ');
			}
		})
		.catch((error) => {
			console.error(error);
		});
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/itemtype/delete/'+ id,
		}).then(reponse => {
			openNotification('Delete Success !', 'Item Type Deleted Successfully, Refreshing Grid. ');
			fetchItemTypeList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};

	const handleTotal = (total, range) => (
		<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
	);

    const columnsNew = [
		{
			title: 'Item Type',
			dataIndex: 'name',
			key: 'name',
			...tableColumnSearch('name'),
			align: 'center',
			width: 300
		},		
        {
			title: 'Current Rate',
			dataIndex: 'current_rate',
			key: 'current_rate',
			...tableColumnSearch('current_rate'),
			align: 'center',
			editable: true,
			width: 300
		},	
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={() => update(record.name)}
							style={{
								marginRight: 8,
							}}
						> Save
						</Typography.Link>
						<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
							<a>Cancel</a>
						</Popconfirm>
					</span>
				) : (
					<Space size="middle" disabled={editingKey !== ''}>
						<EditOutlined
							style={{
								cursor: 'pointer',
								fontSize: '20px',
								color: '#08c',
							}}
							onClick={() => edit(record)}
						/>
						<Popconfirm
							title="Are you sure you want to delete?"
							onConfirm={() => handleDelete(record.name)}
						>
							<DeleteOutlined
								style={{ fontSize: '20px', color: '#FF6868' }}
							/>
						</Popconfirm>
					</Space>
				);}		
		  },
	];

	const mergedColumns = columnsNew.map((col) => {
		if (!col.editable) {
		  return col;
		}
		return {
		  ...col,
		  onCell: (record) => ({
			record,
			inputType: 'text',
			dataIndex: col.dataIndex,
			title: col.title,
			editing: isEditing(record),
		  }),
		};
	  });

	const CategoryAdd = () => {
		return (
			<div style={{ marginTop: '2rem', marginLeft: '2rem', width: '100%', display: 'flex', gap: '20px', justifyContent: 'start', alignItems: 'center' }}>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Item Type</span>
					<Input
						type="text"
						placeholder='Enter Item Type'							
						style={{ width: '250px', marginBottom: '30px' }}
						ref={nameInputRef}							
					/>
				</Space>
				<Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Current Rate</span>
					<Input
						type="number"
						placeholder='Enter Current Market Rate'							
						style={{ width: '250px', marginBottom: '30px' }}
						ref={rateInputRef}							
					/>
				</Space>
				<Button type='primary' 
						style={{backgroundColor: 'green'}} 
						onClick={handleSave}
						icon={<PlusOutlined />}>
						Add Item Type
				</Button>
			</div>
		)
	}

    return (
		<>
			<PageTitle
				imagesrc={ItemImage}
				pageTitle="Item Types"
				buttonList={[{
					buttonName: 'Back',
					className: 'btn-back',
					url: '/slj/items'
				}]}
			/>
			{contextHolder}
			<CategoryAdd />
			{<div className='categoryList' style={{width: '850px'}}>
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={isLoading} size="large">
					<Form form={form} component={false}>
						<Table dataSource={itemTypeList}
						    components={{
								body: {
								  cell: EditableCell,
								},
							}}
							bordered
							columns={mergedColumns}
							rowKey="name"
							rowClassName="editable-row"
							pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
							scroll={{ y: 500 }}
							size='small'
							style={{fontFamily: 'Poppins'}}/>
					</Form>
				</Spin>
			</div>}
		</>
	);
};

export default ItemTypeList;