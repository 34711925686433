import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Space, Spin, Table, Tabs, Tag, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/sales.png';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, FilterFilled } from '@ant-design/icons';
import moment from 'moment';

const StockList = () => {
	const navigate = useNavigate();
	const [stockList, setStockList] = useState([]);	
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		setLoading(true);
		fetchStockList();
	}, []);

	const fetchStockList = () => {
		httpCall({
			method: 'GET',
			url: '/api/stock/list',
		})
			.then((response) => {
				setLoading(false);
				setStockList(response?.data);
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const openNotification = () => {
		api.open({
		  message: 'Delete Success !',
		  duration: 2,
		  description:
			'Stock Deleted Successfully, Refreshing Grid. ',
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleDelete = (item_id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/stock/delete/'+ item_id,
		}).then(reponse => {
			openNotification();
			fetchStockList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};
  
  	const columnsNew = [
        {
			title: 'Item Name',
			dataIndex: 'itemname',
			key: 'itemname',
			align: 'center',
			width: 150,
			sorter: (a, b) => a.itemname.localeCompare(b.itemname),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'PCS',
			dataIndex: 'pcs',
			key: 'pcs',
			align: 'center',
			width: 200,
			sorter: (a, b) => a.pcs - b.pcs, // Sorting based on numerical value
		},
		{
			title: 'Weight',
			dataIndex: 'weight',
			key: 'weight',
			align: 'center',
			width: 200,
			render: (_, record) => record.weight.toFixed(3),
			sorter: (a, b) => a.weight - b.weight, // Sorting based on numerical value
		},
		{
			title: 'Last Updated',
			dataIndex: 'last_updated',
			key: 'last_updated',
			align: 'center',
			width: 200,
			render: (_, record) => {
				return moment(record).format('DD-MM-YYYY');
			}
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
				stockList.length >= 1 ? (
						<Space size="middle">
							<EyeOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>							
							<Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.item_id)}>
								<DeleteOutlined style={{fontSize: '20px', color: '#FF6868'}}/>
							</Popconfirm>
						</Space>
				) : null			
		  },
	];

	return (
		<>
			<PageTitle
				imagesrc={SalesImage}
				pageTitle="Stock List"
				buttonList={[]}
			/>
			{contextHolder}
			{<div className='vendorListArea'>
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
						<Table 
							dataSource={stockList}
							columns={columnsNew}
							rowKey="item_id"
							pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
							scroll={{ y: 570 }}
							style={{fontFamily: 'Poppins'}}/>
				</Spin>
			</div>}
		</>
	);
};

const handleTotal = (total, range) => (
	<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
);

export default StockList;
